import { Button, Checkbox, Drawer, Flex, Typography } from "antd";
import Search from "antd/es/input/Search";
import { ColumnsType } from "antd/lib/table/InternalTable";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import TableAnt from "~/components/Antd/TableAnt";
import POLICIES from "~/modules/policy/policy.auth";
import { useMatchPolicy } from "~/modules/policy/policy.hook";
import ImageProduct from "~/modules/sale/bill/components/ImageProduct";
import {
  useDeleteProductSection,
  useGetProductSection,
  useGetSection_onlyGet,
  useUpdateProductSection,
} from "../section.hook";
import { ProductInSection } from "../section.modal";
import { filterSlug, formatter } from "~/utils/helpers";
import SectionProductSearch from "./SectionProductSearch";
import WithPermission from "~/components/common/WithPermission";
import BtnAdd from "~/components/common/Layout/List/Header/BtnAdd";
import { SECTION_KEY } from "../constants";
type propsType = {
  sectionId?: any;
};
export default function SectionProductControl({
  sectionId,
}: propsType): React.JSX.Element {
  const [section] = useGetSection_onlyGet();
  const [productSection, loading]: [ProductInSection[], boolean] = useGetProductSection(sectionId);
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const [dataClone, setDataClone] = useState<ProductInSection[]>([]);
  const [keyword, setKeyword] = useState<any>();
  const [isSubmitLoading, update] = useUpdateProductSection();
  const canUpdate = useMatchPolicy(POLICIES.UPDATE_SECTIONPRODUCT);
  const [, onDelete] = useDeleteProductSection();
  const onCheckedIsPopup = (e: any, _id: any) => {
    const checked = e.target.checked;
    const submitData = {
      _id,
      isPopup: checked,
    };
    update(submitData);
  };

  const onCheckedHighlight = (e: any, _id: any) => {
    const checked = e.target.checked;
    const submitData = {
      _id,
      highlight: checked,
    };
    update(submitData);
  };
  const columns: ColumnsType = [
    {
      title: "",
      dataIndex: "productId",
      key: "images",
      width: 80,
      render: (productId) => (
        <ImageProduct
          style={{ height: 50 }}
          images={get(productId, "images")}
        />
      ),
    },
    {
      title: "Tên",
      dataIndex: "productId",
      key: "name",
      render: (productId, rc) =>
        get(productId, "codeBySupplier") + " - " + get(productId, "name", ""),
    },
    {
      title: "Giá",
      dataIndex: "finalPrice",
      key: "finalPrice",
      align: "center",
      width: 100,
      render: (finalPrice, rc) =>
        finalPrice < get(rc, "price", 0) ? (
          <Typography.Text strong type="danger">
            {formatter(finalPrice)}{" "}
            <Typography.Text style={{ textDecoration: "line-through",fontSize : 12 }}>
              {formatter(get(rc, "price", 0))}
            </Typography.Text>
          </Typography.Text>
        ) : (
          <Typography.Text strong>
            {formatter(get(rc, "price", 0))}
          </Typography.Text>
        ),
    },
    {
      title: "Đưa lên trang chủ",
      dataIndex: "highlight",
      key: "highlight",
      width: 140,
      align: "center",
      render: (highlight, rc) => (
        <Checkbox
          disabled={!canUpdate}
          checked={highlight}
          onChange={(e) => onCheckedHighlight(e, rc?._id)}
        />
      ),
    },
  ];
  // Only SECTION_HIGHLIGHT have isPopup
  if(get(section,'sectionKey') === SECTION_KEY.SECTION_HIGHLIGHT){
    columns.push({
        title: "Nổi bật",
        dataIndex: "isPopup",
        key: "isPopup",
        width: 70,
        align: "center",
        render: (isPopup, rc) => (
          <Checkbox
            disabled={!canUpdate}
            checked={isPopup}
            onChange={(e) => onCheckedIsPopup(e, rc?._id)}
          />
        ),
      },)
  };
  if(canUpdate){
    columns.push({
        title: "Thao tác",
        dataIndex: "_id",
        key: "_id",
        width: 80,
        align: "center",
        render: (_id) => (
          <Button
            disabled={!canUpdate}
            loading={isSubmitLoading}
            onClick={() => onDelete(_id)}
            danger
            size="small"
          >
            Gỡ
          </Button>
        ),
      },)
  }
  useEffect(() => {
    if (keyword) {
      const filterData = productSection?.filter(
        (item: ProductInSection) =>
          filterSlug(keyword, item?.productId?.name) ||
          filterSlug(keyword, item?.productId?.codeBySupplier)
      );
      setDataClone(filterData);
    } else {
      setDataClone(productSection);
    }
  }, [keyword, productSection]);

  return (
    <div>
      <Flex style={{position : 'sticky',top : 0,zIndex : 2}} gap={10} justify={"space-between"}>
        <Search
          onChange={(e) => setKeyword(e.target.value)}
          style={{ position: "sticky", top: 0, zIndex: 1 }}
          placeholder="Tìm sản phẩm..."
        />
        <WithPermission permission={POLICIES.UPDATE_SECTIONPRODUCT}>
          <Flex style={{ marginBottom: 8 }} justify={"end"}>
            <BtnAdd onClick={showDrawer} />
          </Flex>
        </WithPermission>
      </Flex>
      <TableAnt
        rowKey={rc => rc?._id}
        columns={columns}
        dataSource={dataClone}
        loading={loading}
        pagination={{
          showTotal(total) {
            return `Tổng cộng ${total}`
          },
        }}
      />
      <Drawer title="Tìm kiếm sản phẩm" onClose={onClose} open={open}>
        <SectionProductSearch
          productSection={productSection}
          sectionId={sectionId}
        />
      </Drawer>
    </div>
  );
}
